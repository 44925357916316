<template>
    <li class="node-tree">
        <div class="tree-line badge badge-primary"><feather-icon class="mr-1" icon="MenuIcon"></feather-icon>&nbsp;{{nodeItemData[labelProp]}} <feather-icon class="cursor-pointer ml-auto"  v-if="nodeItemData[childProp] && nodeItemData[childProp].length > 0" @click="needShow = !needShow" icon="MinusIcon"></feather-icon></div>
        <slide-up-down  :active="nodeItemData[childProp].length > 0 && needShow" :duration="600">
        <ul>
            <node v-for="child in nodeItemData[childProp]" :label-prop="labelProp" :child-prop="childProp" :nodeItemData="child" ></node>
        </ul>
        </slide-up-down>
    </li>
</template>



<script>

    import SlideUpDown from 'vue-slide-up-down'

    export default {
        components: {
            SlideUpDown
        },
        name: "node",
        props: {
            nodeItemData: Object,
            childProp: String,
            labelProp: String,
        },
        data() {
            return {
                needShow: true
            }
        },

    };
</script>