<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">

                <b-breadcrumb-item :to="{ name: 'dashboard' }"> <feather-icon    class="align-text-top" icon="HomeIcon" size="15"/></b-breadcrumb-item>

                <b-breadcrumb-item active>{{$t('label_mlm_structure')}}</b-breadcrumb-item>
            </div>
        </div>

        <b-card no-body>

            <b-card-header class="pb-50">
                <h5>{{ $t('label_mlm_structure') }}</h5>
            </b-card-header>

            <b-card-body>
                <b-tabs content-class="mt-1" >

                    <b-col
                            cols="12"
                            md="6"
                            class=" mb-1"
                    >
                        <div class="pb-2 mt-3 table-top-toolbar">

                            <div class="mb-1" v-for="mlm in mlmItems">
                                <template v-if="mlm">
                                    <tree :tree-data="mlm" child-prop="mlm_agent_data" label-prop="name"></tree>
                                </template>
                            </div>

                        </div>

                    </b-col>
                </b-tabs>
            </b-card-body>
        </b-card>


    </div>
</template>

<script>

    import {
         BCardText, BCard, BCardHeader, BCardBody,
        BTabs, BTab, BAlert, BButton, BPopover, BAvatar,

    } from 'bootstrap-vue'

    import tree from './includes/tree'

    import {MODULE_PREFIX as PREFIX, MODULE_PREFIX} from './moduleHelper'

    export default {

        components: {

            BCardText,
            BCard, BCardHeader, BCardBody,
            BTabs, BTab, BAlert, BButton, BPopover, BAvatar,
            tree

        },
        data() {
            return {
                MODULE_PREFIX,
                PREFIX,

                mlmItems: [],
            }
        },

        created() {

            this.async('get', '/' + MODULE_PREFIX , {params:{}}, function(res){

                this.mlmItems = res.data.agent_data;
            });
        },


    }
</script>


<style lang="scss">
    .line-actions {
        position: absolute;
        right: 0;
        top: 0;
    }

    .email-media-list {
        width: 100%;
    }

    .tree-list ul {
        margin: 11px 0;
    }

    .tree-line{
        height:36px;
        align-items: center;
        display: flex;
        padding: 4px 10px;
    }
    .tree-list li {
        margin-bottom: 16px;
    }
</style>